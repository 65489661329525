<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">COMPANY RECEIVABLE (BOD CHARGES)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12>
          <v-form ref="form2" class="multi-col-validation">
            <v-select v-model="status"
                      class="mx-2"
                      dense
                      outlined
                      label="Status"
                      :items="['Unpaid','Paid']"
                      :rules="rules.combobox_rule"
                      @change="selected_status"
            ></v-select>
            <v-checkbox v-model="is_search" label="Search?"
                        @change="selected_status(status)"></v-checkbox>
            <v-autocomplete
              v-if="is_search"
              dense
              outlined
              v-model="search"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label="Search"
              class="mx-2"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
            ></v-autocomplete>
          </v-form>
        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          LEGENDS -
          (SOA-
          <v-icon
            class="mr-2"
            color="success"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          )
        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.title"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-layout col>
                <v-flex xs12 md6 class="mx-2">
                  <v-text-field outlined v-model="total_amount" label="Total Amount"
                                readonly></v-text-field>
                </v-flex>
                <v-flex xs12 md6 class="mx-2">
                  <v-text-field outlined v-model="total_balance" label="Total Balance"
                                readonly></v-text-field>
                </v-flex>
              </v-layout>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.category.category }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?item.cv.transaction_month.month_of:(item.ckv!=null?item.ckv.transaction_month.month_of:(item.ftv!=null?item.ftv.transaction_month.month_of:'')))}}
                    </td>
                    <td>
                      {{ item.branch!=null?item.branch.branch_code:'' }}
                    </td>
                    <td>
                      {{
                      (item.cv!=null?'CV#'+item.cv.voucher_no:(item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.ftv!=null?'FTV#'+item.ftv.voucher_no:'')))}}
                    </td>
                    <td>
                      {{ item.payment_for}}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                    <td>
                      {{ balance_calculation(item) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa_ca(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-layout col>
                <v-flex xs12 md6 class="mx-2">
                  <v-text-field outlined v-model="total_amount2" label="Total Amount"
                                readonly></v-text-field>
                </v-flex>
                <v-flex xs12 md6 class="mx-2">
                  <v-text-field outlined v-model="total_balance2" label="Total Balance"
                                readonly></v-text-field>
                </v-flex>
              </v-layout>
              <v-data-table dense
                            :headers="headers2"
                            :items="data_items2"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.employee!=null?item.employee.last_name+', '+item.employee.first_name
                      +' '+item.employee.middle_name:item.name }}
                    </td>
                    <td>
                      {{ item.purpose }}
                    </td>
                    <td>
                      {{ item.date }}
                    </td>
                    <td>
                      {{formatPrice(item.amount)}}
                    </td>
                    <td>
                      {{ formatPrice(parseFloat(item.total)-parseFloat(item.amount))}}
                    </td>
                    <td>
                      {{formatPrice(item.total)}}
                    </td>
                    <td>
                      {{ balance_calculation(item)}}
                    </td>
                    <td>
                      {{ formatPrice(item.period_amount) }}
                    </td>
                    <td>
                      {{ item.transaction_month.month_of}}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa_loan(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-layout col>
                <v-flex xs12 md6 class="mx-2">
                  <v-text-field outlined v-model="total_amount3" label="Total Amount"
                                readonly></v-text-field>
                </v-flex>
                <v-flex xs12 md6 class="mx-2">
                  <v-text-field outlined v-model="total_balance3" label="Total Balance"
                                readonly></v-text-field>
                </v-flex>
              </v-layout>
              <v-data-table dense
                            :headers="headers3"
                            :items="data_items3"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.employee!=null?item.employee.last_name+', '+item.employee.first_name
                      +' '+item.employee.middle_name:item.name }}
                    </td>
                    <td>
                      {{ item.particulars }}
                    </td>
                    <td>
                      {{ item.purpose }}
                    </td>
                    <td>
                      {{formatPrice(item.amount)}}
                    </td>
                    <td>
                      {{ balance_calculation(item)}}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa_others(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-layout col>
                <v-flex xs12 md6 class="mx-2">
                  <v-text-field outlined v-model="total_amount4" label="Total Amount"
                                readonly></v-text-field>
                </v-flex>
                <v-flex xs12 md6 class="mx-2">
                  <v-text-field outlined v-model="total_balance4" label="Total Balance"
                                readonly></v-text-field>
                </v-flex>
              </v-layout>
              <v-data-table dense
                            :headers="headers4"
                            :items="data_items4"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.brand }}
                    </td>
                    <td>
                      {{ item.unit }}
                    </td>
                    <td>
                      {{ item.plate_no }}
                    </td>
                    <td>
                      {{ formatPrice(item.cash_price) }}
                    </td>
                    <td>
                      {{ formatPrice(item.interest) }}
                    </td>
                    <td>
                      {{ formatPrice(item.total_loan) }}
                    </td>
                    <td>
                      {{ formatPrice(item.borrower_loan )}}
                    </td>
                    <td>
                      {{ formatPrice(item.balance) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa_auto_loan(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-layout col>
                <v-flex xs12 md6 class="mx-2">
                  <v-text-field outlined v-model="total_amount5" label="Total Amount"
                                readonly></v-text-field>
                </v-flex>
                <v-flex xs12 md6 class="mx-2">
                  <v-text-field outlined v-model="total_balance5" label="Total Balance"
                                readonly></v-text-field>
                </v-flex>
              </v-layout>
              <v-data-table dense
                            :headers="headers5"
                            :items="data_items5"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.employee!=null?item.employee.last_name+', '+item.employee.first_name
                      +' '+item.employee.middle_name:item.name }}
                    </td>
                    <td>
                      {{ item.purpose }}
                    </td>
                    <td>
                      {{formatPrice(item.amount)}}
                    </td>
                    <td>
                      {{ balance_calculation(item)}}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa_motor(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiGoogleCirclesCommunities,
    mdiPrinter, mdiTransferRight, mdiFolderSwap, mdiDelete,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Request ID', value: 'tin', sortable: false},
        {text: 'Category', value: 'tin', sortable: false},
        {text: 'Month Of', value: 'tin', sortable: false},
        {text: 'Branch', value: 'tin', sortable: false},
        {text: 'Voucher#', value: 'tin', sortable: false},
        {text: 'Name', value: 'tin', sortable: false},
        {text: 'Description', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Balance', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      headers2: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'tin', sortable: false},
        {text: 'Purpose', value: 'tin', sortable: false},
        {text: 'Date of Loan', value: 'tin', sortable: false},
        {text: 'Loan Amount', value: 'tin', sortable: false},
        {text: 'Interest', value: 'tin', sortable: false},
        {text: 'Total', value: 'tin', sortable: false},
        {text: 'Loan Balance', value: 'tin', sortable: false},
        {text: 'Period Amount', value: 'tin', sortable: false},
        {text: 'Month Of', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      headers3: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'tin', sortable: false},
        {text: 'Particulars', value: 'tin', sortable: false},
        {text: 'Description', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Balance', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      headers4: [
        {text: 'Name', value: 'date_of_deposit', sortable: false},
        {text: 'Brand', value: 'tin', sortable: false},
        {text: 'Unit', value: 'tin', sortable: false},
        {text: 'Plate #', value: 'tin', sortable: false},
        {text: 'Cash Price', value: 'tin', sortable: false},
        {text: 'Interest', value: 'tin', sortable: false},
        {text: 'Total Loan', value: 'tin', sortable: false},
        {text: 'Total Borrower Loan', value: 'tin', sortable: false},
        {text: 'Balance', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      headers5: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'tin', sortable: false},
        {text: 'Description', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Balance', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      data_items: [],
      data_items2: [],
      data_items3: [],
      data_items4: [],
      data_items5: [],
      data_items6: [],
      tab: '',
      tabs: [
        {title: 'CASH ADVANCE', icon: mdiGoogleCirclesCommunities},
        {title: 'ACCOUNTING LOAN', icon: mdiAccountOutline},
        {title: 'OTHER RECEIVABLE', icon: mdiAccountOutline},
        {title: 'AUTO LOAN', icon: mdiAccountOutline},
        {title: 'MOTOR FINANCE', icon: mdiAccountOutline},
        {title: 'DAMAYAN SERVICE', icon: mdiAccountOutline},
      ],
      is_search: false,
      status: '',
      search: '',
      isLoading: false,
      total_amount: '0',
      total_balance: '0',
      total_amount2: '0',
      total_balance2: '0',
      total_amount3: '0',
      total_balance3: '0',
      total_amount4: '0',
      total_balance4: '0',
      total_amount5: '0',
      total_balance5: '0',
      total_amount6: '0',
      total_balance6: '0',
      id: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiTransferRight,
          mdiFolderSwap,
          mdiDelete,
        },
      }
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'address', 'contact', 'name', 'department']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('motor_finance', ['list_of_motor_receivable_bod_charge', 'search_list_of_motor_bod_charge']),
      ...mapActions('loan_application', ['list_of_loan_bod_charge', 'search_list_of_loan_bod_charge']),
      ...mapActions('request_data', ['list_of_cash_advance_bod_charges', 'search_of_cash_advance_bod_charges']),
      ...mapActions('other_receivables', ['list_of_other_receivable_bod_charge', 'search_list_of_other_receivable_bod_charge']),
      ...mapActions('auto_loan', ['list_of_auto_loan_bod_charge', 'search_auto_loan_bod_charge']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      balance_calculation(item) {
        var total = item.amount
        var payment = 0
        if (item.payment != null) {
          item.payment.forEach(function (item) {
            payment += parseFloat(item.amount)
          });

        } else if (item.ca_payment != null) {
          item.ca_payment.forEach(function (item) {
            payment += parseFloat(item.amount)
          });

        }
        var balance = (total - payment)
        return this.formatPrice(balance)
      },
      searching(value) {
        this.search = value
        this.data_items = []
        this.data_items2 = []
        this.total_amount = '0'
        this.total_balance = '0'
        this.search_of_cash_advance_bod_charges({
          search_word: value,
          status: this.status
        })
          .then(response => {
            this.total_amount = response.data[0].total_amount
            this.total_balance = response.data[0].total_balance
            this.data_items = response.data[0].personal
            this.isLoading = false
          })
        this.search_list_of_loan_bod_charge({
          search_word: value,
          status: this.status
        })
          .then(response => {
            this.total_amount2 = response.data[0].total_amount
            this.total_balance2 = response.data[0].total_balance
            this.data_items2 = response.data[0].employee
            this.isLoading = false
          })
        this.search_list_of_other_receivable_bod_charge({
          search_word: value,
          status: this.status
        })
          .then(response => {
            this.total_amount3 = response.data[0].total_amount
            this.total_balance3 = response.data[0].total_balance
            this.data_items3 = response.data[0].employee
            this.isLoading = false
          })
        this.search_auto_loan_bod_charge({
          search_word: value,
          status: this.status
        })
          .then(response => {
            this.data_items4 = response.data[0].active
            this.total_amount4 = this.formatPrice(response.data[0].amount)
            this.total_balance4 = this.formatPrice(response.data[0].balance)
            this.isLoading = false
          })
        this.search_list_of_motor_bod_charge({
          search_word: value,
          status: this.status
        })
          .then(response => {
            this.total_amount5 = response.data[0].total_amount
            this.total_balance5 = response.data[0].total_balance
            this.data_items5 = response.data[0].employee
            this.isLoading = false
          })
      },
      selected_status(value) {
        this.data_items = []
        this.data_items2 = []
        this.total_amount = '0'
        this.total_balance = '0'
        if (!this.is_search) {
          this.list_of_cash_advance_bod_charges({
            status: value
          })
            .then(response => {
              this.total_amount = response.data[0].total_amount
              this.total_balance = response.data[0].total_balance
              this.data_items = response.data[0].personal
              this.isLoading = false
            })
          this.list_of_loan_bod_charge({
            status: value
          })
            .then(response => {
              this.total_amount2 = response.data[0].total_amount
              this.total_balance2 = response.data[0].total_balance
              this.data_items2 = response.data[0].employee
              this.isLoading = false
            })
          this.list_of_other_receivable_bod_charge({
            status: value
          })
            .then(response => {
              this.total_amount3 = response.data[0].total_amount
              this.total_balance3 = response.data[0].total_balance
              this.data_items3 = response.data[0].employee
              this.isLoading = false
            })
          this.list_of_auto_loan_bod_charge({
            status: value
          })
            .then(response => {
              this.data_items4 = response.data[0].active
              this.total_amount4 = this.formatPrice(response.data[0].amount)
              this.total_balance4 = this.formatPrice(response.data[0].balance)
              this.isLoading = false
            })
          this.list_of_motor_receivable_bod_charge({
            status: value
          })
            .then(response => {
              this.total_amount5 = response.data[0].total_amount
              this.total_balance5 = response.data[0].total_balance
              this.data_items5 = response.data[0].employee
              this.isLoading = false
            })
        } else {
          this.searching(this.search)
        }
      },
      print_soa_ca(value) {
        var imgData = this.company_logo
        var payments_array = []
        var widths = [100, 60, 80, 100, 60]
        var payment = 0
        payments_array.push([
          {text: 'Month Of', alignment: 'left', style: 'label',},
          {text: 'Duration', alignment: 'left', style: 'label'},
          {text: 'Date', alignment: 'left', style: 'label'},
          {text: 'Particulars', alignment: 'left', style: 'label'},
          {text: 'Amount', alignment: 'left', style: 'label'},
        ])
        if (value.ca_payment.length > 0) {
          value.ca_payment.forEach(function (item) {
            if (item.deposit_slip != null) {
              payment += parseFloat(item.amount)
              payments_array.push([
                {text: item.transaction_month.month_of, alignment: 'left', style: 'tableExample'},
                {text: item.duration, alignment: 'left', style: 'tableExample'},
                {text: item.deposit_slip.date, alignment: 'left', style: 'tableExample'},
                {text: item.particulars, alignment: 'left', style: 'tableExample'},
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left', style: 'tableExample'
                },
              ])
            }
          })
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'CASH ADVANCE - STATEMENT OF ACCOUNT (SOA)',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'NAME: ',
                        {
                          text: value.payment_for,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Amount: ',
                        {
                          text: this.formatPrice(value.amount),
                          style: 'main_data', color: 'blue',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Branch: ',
                        {
                          text: value.branch != null ? value.branch.branch_code : '',
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Payed: ',
                        {
                          text: this.formatPrice(payment),
                          style: 'main_data', color: 'green',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {text: ''},
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Balance: ',
                        {
                          text: this.formatPrice(parseFloat(value.amount) - parseFloat(payment)),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Purpose: ',
                        {
                          text: value.description,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            'PAYMENT',
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.department,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: this.damayan, width: 54, height: 40, style: 'logo'},
                {image: this.chapel, width: 54, height: 40, style: 'logo'},
                {image: this.factory, width: 54, height: 40, style: 'logo'},
                {image: this.coop, width: 54, height: 40, style: 'logo'},
                {image: this.printing, width: 54, height: 40, style: 'logo'},
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving = false
      },
      print_soa_loan(value) {
        var imgData = this.company_logo
        var payments_array = []
        var widths = [100, 60, 80, 100, 60]
        var payment = 0
        payments_array.push([
          {text: 'Month Of', alignment: 'left', style: 'label',},
          {text: 'Duration', alignment: 'left', style: 'label'},
          {text: 'Date', alignment: 'left', style: 'label'},
          {text: 'Particulars', alignment: 'left', style: 'label'},
          {text: 'Amount', alignment: 'left', style: 'label'},
        ])
        if (value.payment.length > 0) {
          value.payment.forEach(function (item) {
            if (item.deposit_slip != null) {
              payment += parseFloat(item.amount)
              payments_array.push([
                {text: item.transaction_month.month_of, alignment: 'left', style: 'tableExample'},
                {text: item.duration, alignment: 'left', style: 'tableExample'},
                {text: item.deposit_slip.date, alignment: 'left', style: 'tableExample'},
                {text: item.particulars, alignment: 'left', style: 'tableExample'},
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left', style: 'tableExample'
                },
              ])
            }
          })
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'LOAN - STATEMENT OF ACCOUNT (SOA)',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'LOAN ID#: ',
                        {
                          text: value.id,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Amount: ',
                        {
                          text: this.formatPrice(value.amount),
                          style: 'main_data', color: 'blue',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'NAME: ',
                        {
                          text: value.employee != null ? value.employee.last_name + ', ' + value.employee.first_name
                            + ' ' + value.employee.middle_name : value.name,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Interest: ',
                        {
                          text: this.formatPrice((parseFloat(value.total) - parseFloat(value.amount))),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date of Loan: ',
                        {
                          text: value.date,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total: ',
                        {
                          text: this.formatPrice(value.total),
                          style: 'main_data', color: 'orange',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date Expected Paid: ',
                        {
                          text: value.expected_paid_date,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Payed: ',
                        {
                          text: this.formatPrice(payment),
                          style: 'main_data', color: 'green',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {text: ''},
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Balance: ',
                        {
                          text: this.formatPrice(parseFloat(value.total) - parseFloat(payment)),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Purpose: ',
                        {
                          text: value.purpose,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            'PAYMENT',
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.department,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: this.damayan, width: 54, height: 40, style: 'logo'},
                {image: this.chapel, width: 54, height: 40, style: 'logo'},
                {image: this.factory, width: 54, height: 40, style: 'logo'},
                {image: this.coop, width: 54, height: 40, style: 'logo'},
                {image: this.printing, width: 54, height: 40, style: 'logo'},
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving = false
      },
      print_soa_others(value) {
        var imgData = this.company_logo
        var payments_array = []
        var widths = [100, 60, 80, 100, 60]
        var payment = 0
        payments_array.push([
          {text: 'Month Of', alignment: 'left', style: 'label',},
          {text: 'Duration', alignment: 'left', style: 'label'},
          {text: 'Date', alignment: 'left', style: 'label'},
          {text: 'Particulars', alignment: 'left', style: 'label'},
          {text: 'Amount', alignment: 'left', style: 'label'},
        ])
        if (value.payment.length > 0) {
          value.payment.forEach(function (item) {
            if (item.deposit_slip != null) {
              payment += parseFloat(item.amount)
              payments_array.push([
                {text: item.transaction_month.month_of, alignment: 'left', style: 'tableExample'},
                {text: item.duration, alignment: 'left', style: 'tableExample'},
                {text: item.deposit_slip.date, alignment: 'left', style: 'tableExample'},
                {text: item.particulars, alignment: 'left', style: 'tableExample'},
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left', style: 'tableExample'
                },
              ])
            }
          })
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'OTHERE RECEIVABLE - STATEMENT OF ACCOUNT (SOA)',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'ID#: ',
                        {
                          text: value.id,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Amount: ',
                        {
                          text: this.formatPrice(value.amount),
                          style: 'main_data', color: 'blue',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'NAME: ',
                        {
                          text: value.employee != null ? value.employee.last_name + ', ' + value.employee.first_name
                            + ' ' + value.employee.middle_name : value.name,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Payed: ',
                        {
                          text: this.formatPrice(payment),
                          style: 'main_data', color: 'green',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date: ',
                        {
                          text: value.date,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Balance: ',
                        {
                          text: this.formatPrice(parseFloat(value.amount) - parseFloat(payment)),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Purpose: ',
                        {
                          text: value.purpose,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            'PAYMENT',
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.department,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: this.damayan, width: 54, height: 40, style: 'logo'},
                {image: this.chapel, width: 54, height: 40, style: 'logo'},
                {image: this.factory, width: 54, height: 40, style: 'logo'},
                {image: this.coop, width: 54, height: 40, style: 'logo'},
                {image: this.printing, width: 54, height: 40, style: 'logo'},
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving = false
      },
      print_soa_auto_loan(value) {
        var imgData = this.company_logo
        var payments_array = []
        var widths = [100, 60, 80, 100, 60]
        var payment = 0
        payments_array.push([
          {text: 'Month Of', alignment: 'left', style: 'label',},
          {text: 'Duration', alignment: 'left', style: 'label'},
          {text: 'Date', alignment: 'left', style: 'label'},
          {text: 'Particulars', alignment: 'left', style: 'label'},
          {text: 'Amount', alignment: 'left', style: 'label'},
        ])
        if (value.payment.length > 0) {
          value.payment.forEach(function (item) {
            if (item.deposit_slip != null) {
              payment += parseFloat(item.amount)
              payments_array.push([
                {text: item.transaction_month.month_of, alignment: 'left', style: 'tableExample'},
                {text: item.duration, alignment: 'left', style: 'tableExample'},
                {text: item.deposit_slip.date, alignment: 'left', style: 'tableExample'},
                {text: item.particulars, alignment: 'left', style: 'tableExample'},
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left', style: 'tableExample'
                },
              ])
            }
          })
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'AUTO LOAN - STATEMENT OF ACCOUNT (SOA)',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'NAME: ',
                        {
                          text: value.name,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Car Value: ',
                        {
                          text: this.formatPrice(value.cash_price),
                          style: 'main_data', color: 'green',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date of Loan: ',
                        {
                          text: value.date,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Interest: ',
                        {
                          text: this.formatPrice(value.cash_price),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        ' ',
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total: ',
                        {
                          text: this.formatPrice(value.total_loan),
                          style: 'main_data', color: 'blue',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Brand: ',
                        {
                          text: value.brand,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        '----------------------------------------',
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Unit: ',
                        {
                          text: value.unit,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_data',
                      text: [
                        'Share"s ',
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Plate #: ',
                        {
                          text: value.plate_no,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_data',
                      text: [
                        'Company: ',
                        {
                          text: this.formatPrice(value.company_share),
                          style: 'main_data', color: 'orange',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Classification: ',
                        {
                          text: value.vehicle_classification,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_data',
                      text: [
                        'Borrower: ',
                        {
                          text: this.formatPrice(value.borrower_loan),
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        ' ',
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_data',
                      text: [
                        'Total Payment: ',
                        {
                          text: this.formatPrice(payment),
                          style: 'main_data', color: 'blue',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        ' ',
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_data',
                      text: [
                        'Balance: ',
                        {
                          text: this.formatPrice(value.balance),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            'PAYMENT',
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.department,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: this.damayan, width: 54, height: 40, style: 'logo'},
                {image: this.chapel, width: 54, height: 40, style: 'logo'},
                {image: this.factory, width: 54, height: 40, style: 'logo'},
                {image: this.coop, width: 54, height: 40, style: 'logo'},
                {image: this.printing, width: 54, height: 40, style: 'logo'},
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving = false
      },
      print_soa_motor(value) {
        var imgData = this.company_logo
        var payments_array = []
        var widths = [100, 60, 80, 100, 60]
        var payment = 0
        payments_array.push([
          {text: 'Month Of', alignment: 'left', style: 'label',},
          {text: 'Duration', alignment: 'left', style: 'label'},
          {text: 'Date', alignment: 'left', style: 'label'},
          {text: 'Particulars', alignment: 'left', style: 'label'},
          {text: 'Amount', alignment: 'left', style: 'label'},
        ])
        if (value.payment.length > 0) {
          value.payment.forEach(function (item) {
            if (item.deposit_slip != null) {
              payment += parseFloat(item.amount)
              payments_array.push([
                {text: item.transaction_month.month_of, alignment: 'left', style: 'tableExample'},
                {text: item.duration, alignment: 'left', style: 'tableExample'},
                {text: item.deposit_slip.date, alignment: 'left', style: 'tableExample'},
                {text: item.particulars, alignment: 'left', style: 'tableExample'},
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left', style: 'tableExample'
                },
              ])
            }
          })
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'MOTOR FINANCE - STATEMENT OF ACCOUNT (SOA)',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'ID#: ',
                        {
                          text: value.id,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Amount: ',
                        {
                          text: this.formatPrice(value.amount),
                          style: 'main_data', color: 'blue',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'NAME: ',
                        {
                          text: value.employee != null ? value.employee.last_name + ', ' + value.employee.first_name
                            + ' ' + value.employee.middle_name : value.name,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Payed: ',
                        {
                          text: this.formatPrice(payment),
                          style: 'main_data', color: 'green',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date: ',
                        {
                          text: value.date,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Balance: ',
                        {
                          text: this.formatPrice(parseFloat(value.amount) - parseFloat(payment)),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Purpose: ',
                        {
                          text: value.purpose,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            'PAYMENT',
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.department,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: this.damayan, width: 54, height: 40, style: 'logo'},
                {image: this.chapel, width: 54, height: 40, style: 'logo'},
                {image: this.factory, width: 54, height: 40, style: 'logo'},
                {image: this.coop, width: 54, height: 40, style: 'logo'},
                {image: this.printing, width: 54, height: 40, style: 'logo'},
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving = false
      },
    }
  }
</script>
